import React from 'react';
import './App.less';
import TabDiv from './TabDiv';
import { Layout } from 'antd';

const { Content, Footer } = Layout;

const App = () => (
    <div className="App">
        <Layout className="layout">
            <Content style={{ height: '92vh' }}>
                <TabDiv />
            </Content>
            <Footer style={{ textAlign: 'center' }}>Anti Fake ©2022 Created by Shellmiao</Footer>
        </Layout>
    </div>
);

export default App;
