import { Form, Input, Button, Select, notification, message } from 'antd';
import { useState } from 'react';
import { createGood, updateGood } from './Utils/Axios';

const { Option } = Select;

function CreateGoods() {
    const [opt, setOpt] = useState('create');

    const openNotification = (title, des) => {
        const args = {
            message: title,
            description: des,
            duration: 0,
        };
        notification.open(args);
    };

    const showMessage = (resp, mess) => {
        if (resp.indexOf('TxID' !== -1)) {
            message.success(mess + '成功');
            const temp = resp.search('TxID');
            openNotification(mess + '成功', resp.slice(temp));
        } else {
            message.error('发生错误');
            openNotification('发生错误', resp);
        }
    };

    const onFinish = (values) => {
        let resp;
        if (opt === 'create') {
            createGood(values.id, values.desc, values.account, values.contractAccount).then((res) => {
                resp = res.data.resp;
                showMessage(resp, '添加');
            });
        } else {
            updateGood(values.id, values.desc, values.account, values.contractAccount).then((res) => {
                resp = res.data.resp;
                showMessage(resp, '更新');
            });
        }
    };

    const onOptChange = (value) => {
        setOpt(value);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div style={{ marginTop: '3vh', marginBottom: '6vh' }}>
            <Form name="basic" labelCol={{ offset: 2, span: 3 }} wrapperCol={{ offset: 1, span: 15 }} initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off" size={'large'}>
                <Form.Item name="opt" label="opt" rules={[{ required: true }]}>
                    <Select placeholder="请选择你的操作" onChange={onOptChange} allowClear>
                        <Option value="create">创建</Option>
                        <Option value="update">更新</Option>
                    </Select>
                </Form.Item>
                <Form.Item label="id" name="id" rules={[{ required: true, message: '请输入ID' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="desc" name="desc" rules={[{ required: true, message: '请输入描述' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="account" name="account" rules={[{ required: true, message: '请输入账号助记词' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="contractAccount" name="contractAccount" rules={[{ required: true, message: '请输入合约账号' }]}>
                    <Input />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 2, span: 24 }}>
                    <Button type="primary" htmlType="submit" style={{ width: '40vw', margin: 'auto auto' }}>
                        {opt === 'create' ? '创建' : '更新'}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default CreateGoods;
