import { Form, Input, Button, notification, message } from 'antd';
import { addAdmin } from './Utils/Axios';

function AddCompany() {
    const openNotification = (title, des) => {
        const args = {
            message: title,
            description: des,
            duration: 0,
        };
        notification.open(args);
    };

    const onFinish = (values) => {
        addAdmin(values.address, values.account, values.contractAccount).then((res) => {
            const resp = res.data.resp;
            if (resp.indexOf('TxID') !== -1) {
                message.success('添加成功');
                const temp = resp.search('TxID');
                openNotification('添加成功', resp.slice(temp));
            } else {
                message.error('发生错误');
                openNotification('发生错误', resp);
            }
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div style={{ marginTop: '3vh', marginBottom: '6vh' }}>
            <Form name="basic" labelCol={{ offset: 2, span: 3 }} wrapperCol={{ offset: 1, span: 15 }} initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off" size={'large'}>
                <Form.Item label="address" name="address" rules={[{ required: true, message: '请输入地址' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="account" name="account" rules={[{ required: true, message: '请输入账号助记词' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="contractAccount" name="contractAccount" rules={[{ required: true, message: '请输入合约账号' }]}>
                    <Input />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 2, span: 24 }}>
                    <Button type="primary" htmlType="submit" style={{ width: '40vw', margin: 'auto auto' }}>
                        添加
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default AddCompany;
