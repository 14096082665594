import React from 'react';
import './TabDiv.less';
import { Tabs } from 'antd';
import { FileSearchOutlined, FormOutlined, UserAddOutlined } from '@ant-design/icons';
import QueryGoods from './QueryGoods';
import CreateGoods from './CreateGoods';
import AddCompany from './AddCompany';

const { TabPane } = Tabs;

function TabDiv() {
    return (
        <div className="tab-div">
            <Tabs defaultActiveKey="1" centered size={'large'} tabBarGutter={100} animated>
                <TabPane
                    tab={
                        <span>
                            <FileSearchOutlined />
                            查询防伪
                        </span>
                    }
                    key="1"
                >
                    <QueryGoods />
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            <FormOutlined />
                            企业使用
                        </span>
                    }
                    key="2"
                >
                    <CreateGoods />
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            <UserAddOutlined />
                            新增企业
                        </span>
                    }
                    key="3"
                >
                    <AddCompany />
                </TabPane>
            </Tabs>
        </div>
    );
}

export default TabDiv;
