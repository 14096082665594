import { Form, Input, Button, Timeline } from 'antd';
import { useState } from 'react';
import { queryGood } from './Utils/Axios';

function QueryGoods() {
    const [opt, setOpt] = useState([]);

    const onFinish = (values) => {
        queryGood(values.id).then((res) => {
            const resp = res.data.resp;
            const tempSplit = resp.split('\n');
            let temp = [];
            tempSplit.forEach((ele) => {
                if (ele.search('reason') !== -1) {
                    const addTemp = ele.slice(ele.search('reason') + 7);
                    temp.push(addTemp);
                }
            });
            setOpt(temp);
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const timeLine = opt.map((ele, index) => <Timeline.Item key={index}>{ele}</Timeline.Item>);

    return (
        <div style={{ marginTop: '3vh', marginBottom: '6vh' }}>
            <Form name="basic" labelCol={{ offset: 2 }} wrapperCol={{ offset: 2, span: 16 }} initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off" size={'large'}>
                <Form.Item label="id" name="id" rules={[{ required: true, message: 'Please input id!' }]}>
                    <Input />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 2, span: 24 }}>
                    <Button type="primary" htmlType="submit" style={{ width: '40vw', margin: 'auto auto' }}>
                        查询
                    </Button>
                </Form.Item>
            </Form>
            <Timeline mode="alternate">{timeLine}</Timeline>
        </div>
    );
}

export default QueryGoods;
