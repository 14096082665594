import axios from 'axios';
import qs from 'qs';

const rootUrl = 'https://antifakeback.shellmiao.com/';

const instance = axios.create({
    baseURL: rootUrl,
    timeout: 3000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
});

export function queryGood(id) {
    return instance({
        method: 'post',
        url: '/query',
        data: qs.stringify({
            id: id,
        }),
    });
}

export function createGood(id, desc, account, contractAccount) {
    return instance({
        method: 'post',
        url: '/create',
        data: qs.stringify({
            id: id,
            desc: desc,
            account: account,
            contractAccount: contractAccount,
        }),
    });
}

export function updateGood(id, desc, account, contractAccount) {
    return instance({
        method: 'post',
        url: '/update',
        data: qs.stringify({
            id: id,
            desc: desc,
            account: account,
            contractAccount: contractAccount,
        }),
    });
}

export function addAdmin(address, account, contractAccount) {
    return instance({
        method: 'post',
        url: '/addAdmin',
        data: qs.stringify({
            address: address,
            account: account,
            contractAccount: contractAccount,
        }),
    });
}
